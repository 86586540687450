define(['$window'], function($window) {

  

  const transformationSlider = function() {
    const component = {};

    // "config" used to be "options"
    const _config = {
      containerSelector: '[data-js-element="transformationSlider_container"]',
      imageSliderSelector: '[data-js-element="transformationSlider_imageSlider"]',
      beforeElementSelector: '[data-js-element="transformationSlider_beforeImageContainer"]',
      beforeImageSelector: '[data-js-element="transformationSlider_beforeImage"]',
      dragElementSelector: '[data-js-element="transformationSlider_handle"]',
      dragCallback: null,
    };

    const _init = function(element) {
      component.element = element;
      component.getElements(element);
      component.addEventListeners();
      component.dimensions();

      return component;
    };

    const _getElements = function(element) {
      component.container = element.querySelector(component.config.containerSelector);
      component.imageSlider = element.querySelector(component.config.imageSliderSelector);
      component.beforeElement = element.querySelector(component.config.beforeElementSelector);
      component.beforeImage = element.querySelector(component.config.beforeImageSelector);
      component.dragElement = element.querySelector(component.config.dragElementSelector);
    };

    const _addEventListeners = function() {
      component.imageSlider.addEventListener('mousemove', component.onDrag.bind(this));
      component.imageSlider.addEventListener('touchmove', component.onDrag.bind(this));
      component.dragElement.addEventListener('mousedown', component.onDragStart.bind(this));
      component.dragElement.addEventListener('keydown', component.onKeyDown.bind(this));
      component.dragElement.addEventListener('touchstart', component.onDragStart.bind(this));

      $window.addEventListener('mouseup', component.onDragEnd.bind(this));
      $window.addEventListener('resize', component.dimensions.bind(this));
    };

    const _dimensions = function() {
      component.elementWidth = component.imageSlider.offsetWidth;
      component.elementOffsetLeft = component.imageSlider.getBoundingClientRect().left + document.body.scrollLeft;
      component.beforeImage.style.width = `${component.elementWidth}px`;
      component.dragElementWidth = component.dragElement.offsetWidth;
      component.minLeftPos = component.elementOffsetLeft;
      component.maxLeftPos = (component.elementOffsetLeft + component.elementWidth) - component.dragElementWidth;
    };


    const _onDragStart = function(e) {
      e.preventDefault();

      const startX = (e.pageX) ? e.pageX : e.touches[0].pageX;
      const offsetLeft = component.dragElement.getBoundingClientRect().left + document.body.scrollLeft;
      component.posX = (offsetLeft + component.dragElementWidth) - startX;
      component.isDragging = true;
    };
  
    const _onKeyDown = function (e) {
      const getNewLeftWidth = (change) => {
        const stringLeft = component.dragElement.style.left.replace('%', '');
        if (!stringLeft) return;
        const newLeftWidth = Number(stringLeft) + change;
        if (newLeftWidth < 0) return 1;
        if (newLeftWidth > 99) return 99;
        return newLeftWidth;
      };
    
      switch (e.key) {
        case 'ArrowLeft': {
          const newLeftWidth = getNewLeftWidth(-10);
          if (!newLeftWidth) break;
        
          component.dragElement.style.left = `${newLeftWidth}%`;
          component.beforeElement.style.width = `${newLeftWidth}%`;
          break;
        }
        case 'ArrowRight': {
          const newLeftWidth = getNewLeftWidth(10);
          if (!newLeftWidth) break;
        
          component.dragElement.style.left = `${newLeftWidth}%`;
          component.beforeElement.style.width = `${newLeftWidth}%`;
          break;
        }
        default:
          break;
      }
    };

    const _onDragEnd = function(e) {
      e.preventDefault();
      component.isDragging = false;
    };

    const _onDrag = function(e) {
      e.preventDefault();

      if (!component.isDragging) {
        return;
      }

      component.moveX = (e.pageX) ? e.pageX : e.touches[0].pageX;
      component.leftPos = (component.moveX + component.posX) - component.dragElementWidth;

      component.requestDrag($window);
    };

    const _drag = function() {
      if (component.leftPos < component.minLeftPos) {
        component.leftPos = component.minLeftPos;
      } else if (component.leftPos > component.maxLeftPos) {
        component.leftPos = component.maxLeftPos;
      }

      let openRatio = (component.leftPos + (component.dragElementWidth / 2)) - component.elementOffsetLeft;
      openRatio /= component.elementWidth;
      const width = `${openRatio * 100}%`;

      component.dragElement.style.left = width;
      component.beforeElement.style.width = width;

      if (component.config.dragCallback) {
        component.config.dragCallback(openRatio);
      }
    };

    const _requestDrag = function(mWindow) {
      mWindow.requestAnimationFrame(component.drag.bind(this));
    };

    component.config = _config;
    component.init = _init;
    component.getElements = _getElements;
    component.addEventListeners = _addEventListeners;
    component.dimensions = _dimensions;
    component.onDragStart = _onDragStart;
    component.onKeyDown = _onKeyDown;
    component.onDragEnd = _onDragEnd;
    component.onDrag = _onDrag;
    component.drag = _drag;
    component.requestDrag = _requestDrag;

    return component;
  };

  return transformationSlider;
});

